import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

// TODO - Error handling / typing

export const _removeSubscription = async (sku: string) => {
  // await simulateApiError({ path: `/inventory/${sku}/subscriptions` });

  const data = await fetchAPI2(`/inventory/${sku}/subscriptions`, {
    method: "DELETE",
  });

};
