import { query, createAsync } from "@solidjs/router";
import { _listSubscriptions } from "../roma-api/inventory-subscriptions";

export const getListSubscription = query(
 async () => _listSubscriptions(),
  "subscriptions"
);

export const useListSubscriptions = (
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getListSubscription(), options);
