import { ListResponse } from "../types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export type SubscriptionData = {
  SKU: string;
  Email: boolean;
  SMS: string[];
  PlantsWaiting: string[];
  PlantsNotified: string[];
  CreatedAt: string;
  UpdatedAt: string;
};

export const _listSubscriptions = async () => {
  // await simulateApiError({ path: "/inventory/subscriptions" });

  const data = await fetchAPI2<ListResponse<SubscriptionData>>(
    "/inventory/subscriptions"
  );

  return data;
};
