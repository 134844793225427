import { action, json } from "@solidjs/router";
import { _putSubscription, type StockSubscriptionPayload } from "../roma-api/inventory-subscriptions";
import { getListSubscription } from "./useListSubscriptions";
import { getSubscription } from "./useSubscription";

export const addStockSubscriptionAction = action(async(sku: string, payload: StockSubscriptionPayload)=> {
  const response = await _putSubscription(sku, payload)

  // TODO - revalidation? 
  return json(response, {revalidate: [getListSubscription.key, getSubscription.keyFor(sku)]});
})