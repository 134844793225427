import { Component, createSignal, Show, Suspense } from "solid-js";
import { createAsync } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { envelope } from "solid-heroicons/solid";
import { bellAlert } from "solid-heroicons/outline";
import { StockSubscription } from "./StockSubscription";
import { Modal } from "../utility";
import { getSubscription } from "~/services/inventory-subscriptions/useSubscription";
import { checkError } from "~/services/roma-api/checkError";
import { APIError } from "~/services/roma-api/errors";

export const StockSubscribeButton: Component<{
  inventory: any;
  product: any;
}> = (props) => {
  const [isSubscribed, setIsSubscribed] = createSignal(false);
  const [showModal, setShowModal] = createSignal(false);

  const activeSubscription = createAsync(async () => {
    try {
      const data = await getSubscription(props.product.SKU);
      if (data) {
        // what am I looking for in data to determine a subscription..?
        setIsSubscribed(true);
        return data;
      }
    } catch (error) {
      setIsSubscribed(false);
      const err = checkError(error);
      if (
        err instanceof APIError &&
        (err.code === "NO_SUBSCRIPTION" || err.statusCode === 404)
      ) {
        // no valid subscription comes back as a 404 error, don't do anything.
        return;
      }
      throw error;
    }
  });

  return (
    <>
      <button
        aria-label={
          isSubscribed()
            ? "Manage your current subscription"
            : "Subscribe to stock notifications"
        }
        class="mt-2 text-xs border border-roma-blue rounded-full text-roma-blue w-full relative"
        onClick={() => setShowModal(true)}
      >
        <div class="flex justify-center items-center p-1">
          <Icon
            path={isSubscribed() ? bellAlert : envelope}
            class="w-4 h-4 mr-2"
          />
          <span>
            {isSubscribed()
              ? "Manage your current subscription"
              : "Subscribe to stock notifications"}
          </span>
        </div>
        <Show when={isSubscribed()}>
          <div class="w-3 rounded-full aspect-square bg-blue-400 absolute top-1.5 left-1.5 animate-ping" />
          <div class="w-3 rounded-full aspect-square bg-roma-blue absolute top-1.5 left-1.5 " />
        </Show>
      </button>
      <Suspense>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <StockSubscription
            inventory={props.inventory}
            product={props.product}
            activeSubscription={activeSubscription()}
            closeModal={() => setShowModal(false)}
          />
        </Modal>
      </Suspense>
    </>
  );
};
