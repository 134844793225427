
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export type StockSubscriptionPayload = {
  Plants: string[];
  Email: boolean;
  SMS: string[];
};

export const _putSubscription = async (
  sku: string,
  payload: StockSubscriptionPayload
) => {
  // await simulateApiError({ path: `/inventory/${sku}/subscriptions` });

  const data = await fetchAPI2<unknown, StockSubscriptionPayload>(
    `/inventory/${sku}/subscriptions`,
    {
      method: "PUT",
      body: payload,
    }
  );

  return data;
};
