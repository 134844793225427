import {
  Component,
  createSignal,
  Show,
  Suspense,
  ErrorBoundary,
} from "solid-js";
import { createAsync } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { envelope } from "solid-heroicons/solid";
import { bellAlert } from "solid-heroicons/outline";
import { StockSubscription } from "./StockSubscription";
import { Modal } from "../utility";
import { getSubscription } from "~/services/inventory-subscriptions/useSubscription";
import { getProductPricing } from "~/services/products";
import { checkError } from "~/services/roma-api/checkError";
import { GenericError } from "../utility/errors";
import { APIError } from "~/services/roma-api/errors";

export type SubscriptionResponse = {
  SKU: string;
  Email: boolean;
  SMS: string[];
  PlantsWaiting: string[];
  PlantsNotified: string[];
  CreatedAt: string;
  UpdatedAt: string;
};

export const ComingSoonSubscribe: Component<{
  // inventory: any;
  product: any;
}> = (props) => {
  const [isSubscribed, setIsSubscribed] = createSignal(false);
  const [showModal, setShowModal] = createSignal(false);

  // TODO - figure out responses for existing / non existing subscriptions

  const activeSubscription = createAsync(async () => {
    try {
      const data = await getSubscription(props.product.SKU);
      if (data) {
        // what am I looking for in data to determine a subscription..?
        setIsSubscribed(true);
        return data;
      }
    } catch (error) {
      setIsSubscribed(false);
      const err = checkError(error);
      if (err instanceof APIError && err.code === "NO_SUBSCRIPTION") {
        // no valid subscription comes back as a 404 error, don't do anything.
        return;
      }
      throw err;
    }
  });
  const inventory = createAsync(() => getProductPricing(props.product.SKU));

  return (
    <>
      {/* <ErrorBoundary
        fallback={(err, reset) => {
          return <div>hi hi error</div>;
        }}
      > */}
      <Suspense>
        <button
          aria-label={
            isSubscribed()
              ? "Manage your current subscription"
              : "Subscribe to stock notifications"
          }
          class="mt-2 text-sm border border-roma-blue bg-roma-blue text-white hover:text-roma-blue  hover:bg-white rounded-full  w-full relative"
          onClick={() => setShowModal(true)}
        >
          <div class="flex justify-center items-center p-2">
            <span>
              {isSubscribed()
                ? "Manage Notification"
                : "Notify me when available!"}
            </span>{" "}
            <Icon
              path={isSubscribed() ? bellAlert : envelope}
              class="w-4 h-4 ml-2"
            />
          </div>
          <Show when={isSubscribed()}>
            <div class="w-3 rounded-full aspect-square bg-white absolute top-3 left-3 animate-ping" />
            <div class="w-3 rounded-full aspect-square bg-white/80 absolute top-3 left-3 " />
          </Show>
        </button>
        <Suspense>
          <Modal open={showModal} onClose={() => setShowModal(false)}>
            <StockSubscription
              inventory={inventory()}
              product={props.product}
              // TODO - pass accessor, instead of unwrapped ?
              activeSubscription={activeSubscription()}
              closeModal={() => setShowModal(false)}
            />
          </Modal>
        </Suspense>
      </Suspense>
      {/* </ErrorBoundary> */}
    </>
  );
};
