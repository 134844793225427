import { SubscriptionData } from "./listSubscriptions";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _retrieveSubscription = async (
  sku: string,
) => {

  // await simulateApiError({path: `/inventory/${sku}/subscriptions`})

  const data = await fetchAPI2<SubscriptionData>(
    `/inventory/${sku}/subscriptions`,
  );

  return data;
};
