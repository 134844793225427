import {
  Component,
  Show,
  createMemo,
  createEffect,
  Switch,
  Match,
  FlowComponent,
  Suspense,
} from "solid-js";
import {
  A,
  createAsync,
  useAction,
  useNavigate,
  useSubmission,
} from "@solidjs/router";
import { validator } from "@felte/validator-zod";
import * as z from "zod";
import { createForm } from "@felte/solid";
import { MultiSelect } from "../inputs";
import Button from "../Button";
import { Icon } from "solid-heroicons";
import { check, xMark, checkCircle, faceFrown } from "solid-heroicons/outline";
import {
  facilityMapping,
  reverseFacilityMapping,
} from "../ordering/order-page";
import { BaseLoader } from "../utility";
import { stockThreshold, ThresholdStatus } from "~/utils/threshold";
import type { SubscriptionData } from "~/services/roma-api/inventory-subscriptions";
import { getSMSNumbers } from "~/services/sms";
import {
  addStockSubscriptionAction,
  deleteStockSubscriptionAction,
} from "~/services/inventory-subscriptions";
import { getAccountProfile } from "~/services/account";
import { type StockSubscriptionPayload } from "~/services/roma-api/inventory-subscriptions";
import { usePrompt } from "../utility";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";

type StockSubscriptionProps = {
  inventory: any;
  product: any;
  activeSubscription?: SubscriptionData;
  closeModal: () => void;
};

export const StockSubscription: Component<StockSubscriptionProps> = (props) => {
  const { addError } = useErrorContext();
  const navigate = useNavigate();
  // const [Prompt, openPrompt] = usePrompt();

  // * FORM * //

  const schema = z
    .object({
      Plants: z
        .string()
        .array()
        .nonempty({ message: "This field cannot be empty" }),
      Email: z.boolean().optional(),
      Phone: z.boolean().optional(),
      SMS: z.string().array().optional(),
    })
    .refine(
      (data) =>
        data.Phone && (!data.SMS || data.SMS.length < 1) ? false : true,
      {
        message: "At least one number must be selected",
        path: ["SMS"],
      }
    )
    .refine((data) => !(!data.Phone && !data.Email), {
      message: "SMS or Email required for notification subscription",
      path: ["General"],
    });

  const { form, data, setData, errors } = createForm({
    extend: validator({ schema }),
    onSubmit: async (values) => {
      const payload: StockSubscriptionPayload = {
        Plants: [],
        Email: values.Email,
        SMS: [],
      };

      if (values.Plants) {
        payload.Plants = [...values.Plants].map(
          (plant) => reverseFacilityMapping[plant]
        );
      }
      if (values.Phone) {
        payload.SMS = [...values.SMS].map(
          (phone: string) => numbers()?.NumToID[phone.replace("+", "")]
        ) as string[];
      }

      try {
        await subscribe(props.product.SKU, payload);
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            `[StockSubscription.tsx]: Error on subscribe submission for SKU '${props.product.SKU}': `,
            err
          );
        }
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Subscribe Error",
          message: `An error occurred while subscribing to updates for ${props.product.SKU}. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.`,
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    },
  });
  form;

  // * RESOURCES * //

  const numbers = createAsync(async () => {
    try {
      const data = await getSMSNumbers();

      //  dual dictionaries..
      const NumToID: Record<string, string> = {};
      const IDToNum: Record<string, string> = {};

      if (data.Total !== 0) {
        data.Results = data.Results.reverse();
        // populate dictionaries
        data.Results.forEach((entry) => {
          if (entry.PhoneNumber && entry.ID) {
            const strippedNum = entry.PhoneNumber.replace("+", "");

            NumToID[strippedNum] = entry.ID;
            IDToNum[entry.ID] = entry.PhoneNumber;
          }
        });

        if (
          props.activeSubscription &&
          props.activeSubscription?.SMS?.length >= 1
        ) {
          // ?
          const listOfNums = props.activeSubscription.SMS.map(
            (code) => IDToNum[code]
          );

          setData("SMS", listOfNums);
          setData("Phone", true);
        } else {
          setData(
            "SMS",
            [...data.Results].map((item) => item.PhoneNumber)
          );
          if (data.Results.length === 1) {
            setData("Phone", true);
          }
        }
      }
      return { ...data, NumToID, IDToNum };
    } catch (error) {
      // HERE
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          `[StockSubscription.tsx]: Error caught in createAsync: `,
          err
        );
      }
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "An error occurred",
        message: `An error occurred while retrieving your account phone numbers. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.`,
        actions: [
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
      });
    }
  });

  const userDetails = createAsync(async () => {
    const data = await getAccountProfile();

    if (!data.Email) throw new Error("No email found for user.");
    if (!props.activeSubscription) setData("Email", true);
    return data;
  });

  // *  ACTIONS  * //

  const subscribe = useAction(addStockSubscriptionAction);
  const subscribing = useSubmission(addStockSubscriptionAction);

  const unsub = useAction(deleteStockSubscriptionAction);
  const unsubscribing = useSubmission(deleteStockSubscriptionAction);

  const unsubscribe = async (sku: string) => {
    // TODO - usePrompt() Dialog not working with Kobalte Modal
    // TODO - use confirm() for now, swap out with below once Dialog primitive is added..
    // const ok = await openPrompt({
    //   title: "Confirm Unsubscribe",
    //   description: `Are you sure you want to unsubscribe from stock notifications for ${sku}? `,
    //   requireInteraction: true,
    //   options: [
    //     { label: "Cancel", value: false, priority: true },
    //     { label: "Unsubscribe", value: true, style: "red" },
    //   ],
    // });

    const ok = confirm(
      `Are you sure you want to unsubscribe from stock notifications for ${sku}? `
    );

    if (ok) {
      try {
        return await unsub(sku);
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            `[StockSubscription.tsx]: Error on unsubscribe submision for SKU '${sku}': `,
            err
          );
        }
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Unsubscribe Error",
          message:
            "An error occurred while unsubscribing. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    }
  };

  const lowStockPlants = createMemo(() => {
    if (!props.inventory?.Inventory) {
      return [];
    }

    const filtered = Object.entries(props.inventory.Inventory)
      .map(([plant, qty]) => ({
        plant,
        qty,
      }))
      .filter((item) => {
        return [ThresholdStatus.lowStock, ThresholdStatus.zeroStock].includes(
          stockThreshold(
            Number(item.qty),
            props.product?.Category,
            props.product?.Discontinued
          ).status
        );
      });
    return filtered;
  });

  createEffect(() => {
    if (!lowStockPlants() || lowStockPlants().length === 0) return;

    if (props.activeSubscription) {
      // IF there is an active subscription, convert plant codes
      // to their mapping and set on form store:
      const listOfPlants = props.activeSubscription.PlantsWaiting.map(
        (plant) => facilityMapping[plant]
      );
      setData((storeData) => ({
        ...storeData,
        Plants: listOfPlants,
        Email: props.activeSubscription?.Email,
      }));
    } else {
      // ELSE loop through the list of low-stock plants and check
      // if the users default plant is in that list. IF so, set the
      // default plant as the selection on the form store. ELSE set
      // the plant selection as ALL of the low-stock plants.
      for (const item of lowStockPlants()) {
        if (item.plant === props.inventory.Plant) {
          setData("Plants", [facilityMapping[item.plant]]);
          break;
        } else {
          const list = lowStockPlants().map(
            (item) => facilityMapping[item.plant]
          );
          setData("Plants", list);
        }
      }
    }

    // ? NUMBERS are being set in the resource - should maybe be located in this effect?
  });

  const LoaderTemplate: FlowComponent = (props) => {
    return (
      <div class="border border-gray-300 rounded-md h-10 flex items-center w-full px-2">
        <BaseLoader width="6" height="6" />
        <p class="text-sm text-roma-medium-grey">{props.children}</p>
      </div>
    );
  };

  const ErrorTemplate: FlowComponent = (props) => {
    return (
      <div class="bg-red-50 w-full rounded-md py-2 px-3">
        <p class="text-sm text-roma-dark-grey">
          {props.children} please try again or contact{" "}
          <A href="/support" class="text-roma-blue">
            support
          </A>{" "}
          for assistance.
        </p>
      </div>
    );
  };

  return (
    <div class="flex flex-col gap-4 text-roma-dark-grey">
      {/* TODO - Error Handling */}
      {/* <Show when={numbers.error}>ERROR</Show> */}
      <p class="text-2xl">
        Subscribe to stock notifcations for moulding {props.product?.SKU}
      </p>
      <p class="mb-2">
        Never miss out on stock availability! Select your preferred distribution
        center(s) and receive SMS or email notifications. Stay informed and get
        notified when this moulding is back in stock.
      </p>
      <form use:form class="flex flex-col gap-4">
        <div>
          <p class="text-sm text-roma-dark-grey font-bold mb-2">
            Distribution Center(s)
          </p>
          <MultiSelect
            name="Plants"
            options={lowStockPlants().map(
              (item) => facilityMapping[item.plant]
            )}
            value={data().Plants}
            onChange={(selected: any) => {
              setData("Plants", selected);
            }}
          />
          <Show when={errors().Plants}>
            <p class="text-sm text-red-500 mt-1">{errors().Plants}</p>
          </Show>
          <p class="text-xs mt-1 text-roma-dark-grey">
            Please note that choosing multiple distribution centers will
            generate individual notifications.
          </p>
        </div>
        <Show when={errors().General}>
          <p class="text-sm text-red-500 mt-1">{errors().General}</p>
        </Show>
        <div>
          <p class="text-roma-dark-grey text-sm mb-2 font-bold">Email</p>
          <div class="flex items-center">
            <input type="checkbox" id="Email" name="Email" class="sr-only" />
            <label
              for="Email"
              class={`aspect-square w-10 rounded-md mr-2 flex justify-center items-center cursor-pointer ${
                data().Email ? "bg-roma-blue" : "bg-roma-grey border"
              } overflow-hidden transition`}
            >
              <Icon
                path={data().Email ? check : xMark}
                class={`w-5 h-5 ${
                  data().Email ? "text-white" : "text-gray-400"
                }`}
                stroke-width={3}
              />
            </label>
            <Suspense
              fallback={
                <LoaderTemplate>
                  Retrieving registered email address
                </LoaderTemplate>
              }
            >
              <Switch>
                <Match when={userDetails()?.Email}>
                  <div class="bg-roma-grey/50 border rounded-md w-full flex items-center py-2 px-3 text-sm text-roma-dark-grey cursor-not-allowed">
                    <p>{userDetails()?.Email}</p>
                  </div>
                </Match>
                {/* <Match when={userDetails.loading}>
                <LoaderTemplate>
                  Retrieving registered email address
                </LoaderTemplate>
              </Match> */}

                {/* TODO - WRAP SUSPENSE IN ERROR BOUNDARY? */}

                {/* <Match when={userDetails.error}>
                  <ErrorTemplate>Error retrieving email,</ErrorTemplate>
                </Match> */}
              </Switch>
            </Suspense>
          </div>
        </div>
        <div>
          <p class="text-roma-dark-grey text-sm mb-2 font-bold">Mobile</p>
          <div class="flex items-center">
            <input type="checkbox" id="Phone" name="Phone" class="sr-only" />
            <label
              for="Phone"
              class={`aspect-square w-10 rounded-md mr-2 flex justify-center items-center cursor-pointer ${
                data().Phone ? "bg-roma-blue" : "bg-roma-grey border"
              } overflow-hidden transition`}
            >
              <Icon
                path={data().Phone ? check : xMark}
                class={`w-5 h-5 ${
                  data().Phone ? "text-white" : "text-gray-400"
                }`}
                stroke-width={3}
              />
            </label>
            <Switch>
              <Match when={numbers()}>
                <Switch>
                  <Match when={numbers()?.Total === 0}>
                    <div class="bg-roma-grey rounded-md w-full flex items-center py-2 px-3 text-sm text-roma-dark-grey">
                      <p>
                        Your account does not have a registered SMS mobile
                        number, please click{" "}
                        <A href="/account/mobile" class="text-roma-blue">
                          here
                        </A>{" "}
                        to register.
                      </p>
                    </div>
                  </Match>
                  <Match when={numbers()?.Total! >= 1 && numbers()?.Results}>
                    <MultiSelect
                      name="SMS"
                      value={data().SMS}
                      options={
                        numbers()?.Results.map(
                          (item) => item.PhoneNumber
                        ) as string[]
                      }
                      onChange={(option: any) => {
                        setData("SMS", option);
                      }}
                    />
                  </Match>
                </Switch>
              </Match>
              <Match when={!numbers()}>
                <div class="bg-red-50 border rounded-md w-full flex items-center py-2 px-3 text-sm text-red-500 cursor-not-allowed">
                  Error retrieving numbers
                </div>
              </Match>

              {/* TODO - deal with .loading and .error -> Suspense/ErrorBoundary combo? */}

              {/* <Match when={numbers.loading}>
                <LoaderTemplate>Retrieving mobile numbers</LoaderTemplate>
              </Match>
              <Match when={numbers.error}>
                <ErrorTemplate>Error retrieving mobile numbers,</ErrorTemplate>
              </Match> */}
            </Switch>
          </div>
          <Show when={errors().SMS}>
            <p class="text-sm text-red-500 mt-1 block">{errors().SMS}</p>
          </Show>
        </div>

        <Show when={subscribing.result || unsubscribing.result}>
          <div class="flex flex-col justify-center items-center px-3 py-4 bg-green-50 border border-green-100 rounded-lg -mb-6">
            <Icon
              path={checkCircle}
              class="w-10 h-10 text-success-green  mb-2"
            />
            <Switch>
              <Match when={unsubscribing.result}>
                <p>Unsubscribe was successful</p>
              </Match>
              <Match when={subscribing.result}>
                <p>
                  {props.activeSubscription
                    ? "Preferences successfully updated!"
                    : "Subscription successfull!"}
                </p>
              </Match>
            </Switch>
          </div>
        </Show>
        <Show when={subscribing.error || unsubscribing.error}>
          <div class="flex flex-col justify-center items-center px-3 py-4 bg-red-50 border border-red-100 rounded-lg -mb-6">
            <Icon path={faceFrown} class="w-10 h-10 text-orange-600  mb-2" />
            <p class="text-center">
              Something went wrong, please try again later or contact{" "}
              <A href="/support" class="text-roma-blue hover:underline">
                support
              </A>{" "}
              for assistance. We apologize for the inconvenience.
            </p>
          </div>
        </Show>
        <Button
          type="submit"
          class="w-full mt-6"
          disabled={subscribing.pending}
        >
          {props.activeSubscription ? "Update Preferences" : "Subscribe"}
        </Button>
        <Show when={props.activeSubscription}>
          <button
            type="button"
            class="text-red-500 text-sm self-end mr-2"
            disabled={subscribing.pending}
            onClick={() => unsubscribe(props.product.SKU)}
          >
            Unsubscribe
          </button>
        </Show>
      </form>
      {/* {Prompt} */}
    </div>
  );
};
