import { action, json } from "@solidjs/router";
import { _removeSubscription } from "../roma-api/inventory-subscriptions";
import { getListSubscription } from "./useListSubscriptions";
import { getSubscription } from "./useSubscription";

export const deleteStockSubscriptionAction = action(async (sku: string) => {
  const response = await _removeSubscription(sku);

  // TODO - revalidation? 
  return json(response, {revalidate: [getListSubscription.key, getSubscription.keyFor(sku)]})
});
