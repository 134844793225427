import { query, createAsync } from "@solidjs/router";
import { _retrieveSubscription } from "../roma-api/inventory-subscriptions";

export const getSubscription = query(
  (sku) => _retrieveSubscription(sku),
  "stock-subscriptions"
);

export const useSubscription = (
  sku: () => string,
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getSubscription(sku()), options);
